import apiCall, { makeUrl } from '../../utils/apiCall';
import { call, put, takeLatest } from 'redux-saga/effects';

import moment from 'moment';
import { push } from 'connected-react-router';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function refreshPage () {
  window.location.reload(false);
}

function safe (sagaFn) {
  return function * (args) {
    try {
      const result = yield call(sagaFn, args);
      return result;
    } catch (error) {
      yield put({ type: 'SET_ERROR', error: (error.response && error.response.data.message) || error.message });
      yield put({ type: 'CLEAR_LOADING' });
    }
  };
}

function * tocNotice (showToc) {
  showToc = false;
  yield put({ type: 'SHOW_TOC', showToc: showToc });
}

function * listLicenses () {
  const listLicenseCall = yield apiCall();
  const result = yield listLicenseCall.get(makeUrl('/license/v2/list'));
  yield put({ type: 'SAVE_LICENSES', licenses: result.data.licenses });
}

function * downloadLicense ({ licenseId, hardwareId, checkoutTimeframe, callback }) {
  const downloadLicenseCall = yield apiCall();
  const result = yield downloadLicenseCall.post(makeUrl('/license/download256'), { 'license-id': licenseId, 'hardware-id': hardwareId, ttl: checkoutTimeframe });
  yield put({ type: 'SET_FILE', file: result.data });
  yield put({ type: 'SET_SUCCESS', success: 'This license has been generated!' });
  if (callback) {
    yield callback();
  }
}

// function * RquestLicense ({ licenseId }) {
//   const requestLicenseCall = yield apiCall();
//   yield requestLicenseCall.post(makeUrl('/license/request'), { 'license-id': licenseId });
//   // yield put({ type: 'SAVE_LICENSES', licenses: result.data.licenses });
//   yield put({ type: 'SET_SUCCESS', success: 'This request was successful.' });
// }

function * labelLicense ({ licenseId, licenseLabel }) {
  yield put({ type: 'SET_LOADING', key: 'label-license-' + licenseId, loading: true });
  const labelLicenseCall = yield apiCall();
  yield labelLicenseCall.patch(makeUrl('/license/label'), { 'license-id': licenseId, 'license-label': licenseLabel });
  yield put({ type: 'SAVE_LABEL', friendlyId: licenseLabel });
  yield put({ type: 'SET_LOADING', key: 'label-license-' + licenseId, loading: false });
}

function * delegateLicense ({ licenseId, email }) {
  const delegateLicenseCall = yield apiCall();
  yield delegateLicenseCall.patch(makeUrl('/license/delegate'), { 'license-id': licenseId, 'user-email': email });
  if (email === '') {
    yield put({ type: 'SET_SUCCESS', success: 'License has been repossessed.' });
  } else {
    yield put({ type: 'SET_SUCCESS', success: 'This license has been delegated.' });
  }
  yield call(delay, 1000);
  yield call(refreshPage);
}

function * revokeLicense ({ licenseId, hardwareId }) {
  const revokeLicenseCall = yield apiCall();
  yield revokeLicenseCall.post(makeUrl('/license/revoke'), { 'license-id': licenseId, 'hardware-id': hardwareId });
  yield put({ type: 'SET_SUCCESS' });
}

// admin sagas

function * listAllLicenses ({ isActive }) {
  const listAllLicensesCall = yield apiCall();

  if (isActive) {
    const result = yield listAllLicensesCall.get(makeUrl('/admin/v2/listAll?active=1'));
    yield put({ type: 'SAVE_ALL_LICENSES', allLicenses: result.data.licenses });
  } else {
    const result = yield listAllLicensesCall.get(makeUrl('/admin/v2/listAll'));
    yield put({ type: 'SAVE_ALL_LICENSES', allLicenses: result.data.licenses });
  }
}
function * downloadLicenseAll ({ licenseId, hardwareId, checkoutTimeframe, callback }) {
  const downloadLicenseCall = yield apiCall();
  const result = yield downloadLicenseCall.post(makeUrl('/admin/downloadAny'), { 'license-id': licenseId, 'hardware-id': hardwareId, ttl: checkoutTimeframe });
  yield put({ type: 'SET_FILE', file: result.data });
  yield put({ type: 'SET_SUCCESS', success: 'This license has been generated!' });
  if (callback) {
    yield callback();
  }
}

function * labelLicenseAll ({ licenseId, licenseLabel }) {
  const labelLicenseCall = yield apiCall();
  yield labelLicenseCall.patch(makeUrl('/admin/labelAny'), { 'license-id': licenseId, 'license-label': licenseLabel });
  yield put({ type: 'SAVE_LABEL', friendlyId: licenseLabel });
}

function * listAllUsers () {
  const listAllUsersCall = yield apiCall();
  const result = yield listAllUsersCall.get(makeUrl('/admin/listAllUsers'));
  yield put({ type: 'SAVE_ALL_USERS', allUsers: result.data.users });
}

function * issueNewLicense ({ email, name, expiration, ttl, licenseType, isactive }) {
  const issueNewLicense = yield apiCall();
  const { data: { message: result } } = yield issueNewLicense.post(makeUrl('/admin/create'), {
    email,
    name,
    expiration: moment(expiration).utc().format('YYYY-MM-DD hh:mm:ss'),
    ttl,
    type: licenseType,
    isactive
  });

  yield put({ type: 'LICENSE_ISSUED', result });
}

function * editLicense ({ username, name, licenseId, expiration, ttl, licenseType, isactive }) {
  const issueNewLicense = yield apiCall();
  yield issueNewLicense.patch(makeUrl('/admin/update'), {
    name,
    'license-id': licenseId,
    expiration: moment(expiration).format('YYYY-MM-DD'),
    ttl,
    type: licenseType,
    username,
    isactive
  });
  yield put({ type: 'SET_SUCCESS', success: 'This license has been updated.' });
  yield put(push('/all-licenses'));
}

function * delegateLicenseAll ({ licenseId, email }) {
  const delegateLicenseAllCall = yield apiCall();
  yield delegateLicenseAllCall.patch(makeUrl('/admin/delegateAny'), { 'license-id': licenseId, 'user-email': email });
  if (email === '') {
    yield put({ type: 'SET_SUCCESS', success: 'License has been repossessed.' });
  } else {
    yield put({ type: 'SET_SUCCESS', success: 'This license has been delegated.' });
  }
  yield call(delay, 1000);
  yield call(refreshPage);
}

function * revokeLicenseAll ({ licenseId }) {
  const revokeLicenseCall = yield apiCall();
  yield revokeLicenseCall.patch(makeUrl('/admin/revokeAny'), { 'license-id': licenseId });
  yield put({ type: 'SET_SUCCESS', success: 'This license has been revoked.' });
  yield call(delay, 1000);
  yield call(refreshPage);
}

function * promoteToAdmin (username) {
  const promoteToAdminCall = yield apiCall();
  yield promoteToAdminCall.patch(makeUrl('/admin/AddUserToGroup'), username);
  yield put({ type: 'SET_SUCCESS', success: 'This user has been promoted to admin.' });
}

function * demoteToUser (username) {
  const demoteToUserCall = yield apiCall();
  yield demoteToUserCall.patch(makeUrl('/admin/RemoveUserFromGroup'), username);
  yield put({ type: 'SET_SUCCESS', success: 'This user is no longer an admin.' });
}

function * forcePasswordChange (username) {
  const passwordResetCall = yield apiCall();
  yield passwordResetCall.patch(makeUrl('/admin/ResetUserPassword'), username);
  yield put({ type: 'SET_SUCCESS', success: 'This account password has been reset.' });
}

function * resendVerificationEmail (username) {
  const passwordResetCall = yield apiCall();
  yield passwordResetCall.patch(makeUrl('/admin/ResendSignUp'), username);
  yield put({ type: 'SET_SUCCESS', success: 'A verification email has been resent. Pleaes have the user check their email.' });
}

function * enableUser (username) {
  const passwordResetCall = yield apiCall();
  yield passwordResetCall.patch(makeUrl('/admin/EnableUser'), username);
  yield put({ type: 'SET_SUCCESS', success: 'This user has been enabled.' });
  yield call(delay, 1000);
  yield call(refreshPage);
}

function * disableUser (username) {
  const passwordResetCall = yield apiCall();
  yield passwordResetCall.patch(makeUrl('/admin/DisableUser'), username);
  yield put({ type: 'SET_SUCCESS', success: 'This user has been disabled.' });
  yield call(delay, 1000);
  yield call(refreshPage);
}

function * openCustomerInStripe (action) {
  const { stripeCustomerId } = action; // Destructure stripeCustomerId from the action
  if (!stripeCustomerId) {
    yield put({ type: 'SET_ERROR', error: 'No customer ID found.' });
    return;
  }
  console.log(stripeCustomerId);
  window.open(`https://manage.stripe.com/customers/${stripeCustomerId}`, '_blank');
}

function * sagas () {
  yield takeLatest('TOC_NOTICE', safe(tocNotice));
  yield takeLatest('LIST_LICENSES', safe(listLicenses));
  yield takeLatest('DOWNLOAD_LICENSE', safe(downloadLicense));
  // yield takeLatest('REQUEST_LICENSE', RequestLicense);
  yield takeLatest('UPDATE_LABEL', safe(labelLicense));
  yield takeLatest('DELEGATE_LICENSE', safe(delegateLicense));
  yield takeLatest('REVOKE_LICENSE', safe(revokeLicense));

  // admin sagas here
  yield takeLatest('LIST_ALL_LICENSES', safe(listAllLicenses));
  yield takeLatest('DOWNLOAD_LICENSE_ALL', safe(downloadLicenseAll));
  yield takeLatest('UPDATE_LABEL_ALL', safe(labelLicenseAll));
  yield takeLatest('LIST_USERS', safe(listAllUsers));
  yield takeLatest('ISSUING_LICENSE', safe(issueNewLicense));
  yield takeLatest('EDIT_LICENSE', safe(editLicense));
  yield takeLatest('DELEGATE_LICENSE_ALL', safe(delegateLicenseAll));
  yield takeLatest('REVOKE_LICENSE_ALL', safe(revokeLicenseAll));

  // admin actions here
  yield takeLatest('PROMOTE_TO_ADMIN', safe(promoteToAdmin));
  yield takeLatest('DEMOTE_TO_USER', safe(demoteToUser));
  yield takeLatest('FORCE_PASSWORD_RESET', safe(forcePasswordChange));
  yield takeLatest('RESEND_VERIFICATION_EMAIL', safe(resendVerificationEmail));
  yield takeLatest('ENABLE_USER', safe(enableUser));
  yield takeLatest('DISABLE_USER', safe(disableUser));
  yield takeLatest('OPEN_CUSTOMER_IN_STRIPE', safe(openCustomerInStripe));
}

export default sagas;
